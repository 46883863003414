import { configureStore} from "@reduxjs/toolkit";
import {createLogger}  from "redux-logger";

import rootReducer from "./rootReducer";

// import supabaseAuthMiddleware from "./middleware/supabaseAuthMiddleware";

const logger = createLogger()
const middlewares = []

if (process.env.NODE_ENV !== "production") {
	middlewares.push(logger);
}

export const store = configureStore({
	reducer: rootReducer,
	middleware:(getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
	devTools: process.env.NODE_ENV !== "production",
});
